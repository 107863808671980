import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import HomeContainer from "./containers/HomeContainer";
import ServicesContainer from "./containers/ServicesContainer";
import AboutUsContainer from "./containers/AboutUsContainer";
import Privacy from "./containers/Privacy";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<HomeContainer />} path="/" />
        <Route element={<ServicesContainer />} path="/services" />
        <Route element={<AboutUsContainer />} path="/about_us" />
        <Route element={<Privacy />} path="/privacy-policy" />
      </Routes>
    </Router>
  );
}

export default App;
